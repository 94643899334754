
import { defineComponent, ref, onMounted, computed } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddUserModal from "@/views/user-management/system-users/AddUserModal.vue";
import FilterDropdown from "@/views/user-management/system-users/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { useRoute } from "vue-router";
import { setModuleBCN } from "@/core/directive/function/common";
import { ApiAuth } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  status: number;
}

interface FilterOption {
  field: string;
  value: string | number;
  from?: string;
  to?: number;
  condition?: string;
}

interface SortOption {
  field: string;
  direction: string;
}

export default defineComponent({
  name: "user-index",
  props: {
    type: {
      type: Number,
      default: 0,
    },
  },
  components: {
    MBDatatable,
    AddUserModal,
    FilterDropdown,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const loading = ref(true);
    const tableData = ref<Array<User>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "user_type",
        value: props.type,
        condition: "eq",
      },
    ]);
    const sortOptions = ref<Array<SortOption>>([]);

    const tableHeader = ref([
      {
        name: t("user.id"),
        key: "id",
        sortable: true,
      },
      {
        name: t("user.userName"),
        key: "username",
        sortable: true,
      },
      {
        name: t("user.email"),
        key: "email",
        sortable: true,
      },
      {
        name: t("user.firstName"),
        key: "firstname",
        sortable: true,
      },
      {
        name: t("user.lastName"),
        key: "lastname",
        sortable: true,
      },
      {
        name: t("common.status"),
        key: "status",
        sortable: true,
      },
      {
        name: t("common.actions"),
        key: "actions",
        sortable: false,
      },
    ]);

    const getRoute = computed(() => {
      if (props.type === 0) {
        return "/user-management/system-users/";
      }
      return "/user-management/saas-users/";
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getUserList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    });

    const getUserList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiAuth.getUserList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getUserList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getUserList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getUserList(
        currentPage.value,
        pagesize,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getUserList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "") {
          if (item == "status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(1, filterOptions.value.length, ...filterArr);
      getUserList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getUserList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      //   let sts = {
      //     field: "status",
      //     value: "10",
      //   };
      //   filterOptions.value.splice(1, filterOptions.value.length, sts);
      filterOptions.value.splice(1, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getUserList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getRoute,
      getUserList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
    };
  },
});
